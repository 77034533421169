export default {
  path: "/inspection",
  name: "inspection",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    // {
    //   path: "inspection_report_list",
    //   name: "inspection_report_list",
    //   meta: { title: "质检报告" },
    //   component: () => import("@/views/inspection/inspectionReportList/index"),
    // },
    // {
    //   path: "inspection_report_create",
    //   name: "inspection_report_create",
    //   meta: { title: "新增质检报告" },
    //   component: () => import("@/views/inspection/inspectionReportCreate/index"),
    // },
    // {
    //   path: "inspection_report_update",
    //   name: "inspection_report_update",
    //   meta: { title: "编辑质检报告" },
    //   component: () => import("@/views/inspection/inspectionReportUpdate/index"),
    // },
    // {
    //   path: "inspection_report_detail",
    //   name: "inspection_report_detail",
    //   meta: { title: "质检报告详情" },
    //   component: () => import("@/views/inspection/inspectionReportDetail/index"),
    // },
    {
      path: "common_defect",
      name: "common_defect",
      meta: { title: "常见缺陷" },
      component: () => import("@/views/inspection/commonDefect/index"),
    },
    {
      path: "inspection_point",
      name: "inspection_point",
      meta: { title: "检测项" },
      component: () => import("@/views/inspection/inspectionPoint/index"),
    },
    {
      path: "inspection_template",
      name: "inspection_template",
      meta: { title: "检测模板" },
      component: () => import("@/views/inspection/inspectionTemplate/index"),
    },
    {
      path: "pending_inspection_task",
      name: "pending_inspection_task",
      meta: { title: "待检任务" },
      component: () => import("@/views/inspection/pendingInspectionTask/index"),
    },
    {
      path: "incoming_inspection_list",
      name: "incoming_inspection_list",
      meta: { title: "来料检验" },
      component: () => import("@/views/inspection/incomingInspectionList/index"),
    },
    {
      path: "process_inspection_list",
      name: "process_inspection_list",
      meta: { title: "过程检验" },
      component: () => import("@/views/inspection/processInspectionList/index"),
    },
    {
      path: "delivery_inspection_list",
      name: "delivery_inspection_list",
      meta: { title: "发货检验" },
      component: () => import("@/views/inspection/deliveryInspectionList/index"),
    },
  ],
};
