export default {
  path: '/production',
  name: 'production',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/production/plan',
  children: [
    {
      path: 'plan',
      meta: { title: '生产工单' },
      component: () => import('@/views/production/productionPlan/index'),
    },
    {
      path: 'detial',
      meta: { title: '生产工单详情' },
      component: () => import('@/views/production/productionDetail/index'),
    },
    {
      path: 'scheduling',
      meta: { title: '工单排产' },
      component: () => import('@/views/production/productionScheduling/index'),
    },
    {
      path: 'task',
      meta: { title: '生产任务' },
      component: () => import('@/views/production/productionTask/index'),
    },
    {
      path: 'record',
      meta: { title: '生产记录' },
      component: () => import('@/views/production/productionRecord/index'),
    },
    {
      path: 'workshop',
      meta: { title: '车间管理' },
      component: () => import('@/views/production/workshop/index'),
    },
    {
      path: 'workstation',
      meta: { title: '工作站管理' },
      component: () => import('@/views/production/workstation/index'),
    },
    {
      path: 'process',
      meta: { title: '工序管理' },
      component: () => import('@/views/production/process/index'),
    },
    {
      path: 'production_route',
      meta: { title: '工艺路线' },
      component: () => import('@/views/production/productionRoute/index'),
    },
  ],
}