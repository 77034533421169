export default {
  path: "/approval",
  name: "approval",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "approval_task",
      name: "approval_task",
      meta: { title: "待审批任务" },
      component: () => import("@/views/approval/approvalTask/index"),
    },
    {
      path: "approval_order",
      name: "approval_order",
      meta: { title: "我的申请" },
      component: () => import("@/views/approval/approvalOrder/index"),
    },
    {
      path: "approval_record",
      name: "approval_record",
      meta: { title: "历史审批任务" },
      component: () => import("@/views/approval/approvalRecord/index"),
    },
    {
      path: "approval_sales_detail",
      name: "approval_sales_detail",
      meta: { title: "审批销售详情" },
      component: () => import("@/views/approval/approvalSalesDetail/index"),
    },
  ],
};
