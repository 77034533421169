export default {
  path: "/purchasing",
  name: "purchasing",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/purchasing/purchase_create",
  children: [
    {
      path: "purchase_create",
      name: "purchase_create",
      meta: { title: "新增采购单", permission: "purchase_create" },
      component: () => import("@/views/purchasing/purchaseCreate/index"),
    },
    {
      path: "purchase_update",
      meta: { title: "编辑采购单", permission: "purchase_update" },
      component: () => import("@/views/purchasing/purchaseUpdate/index"),
    },
    {
      path: "purchase_record",
      meta: { title: "采购单", permission: "purchase_record" },
      component: () => import("@/views/purchasing/purchaseRecord/index"),
    },
    {
      path: "purchase_record_detail",
      meta: { title: "采购单详情", permission: "purchase_record_detail" },
      component: () => import("@/views/purchasing/purchaseRecordDetail/index"),
    },
    {
      path: "purchase_return_create",
      meta: { title: "新增采购退货单", permission: "purchase_return_create" },
      component: () => import("@/views/purchasing/purchaseReturnCreate/index"),
    },
    {
      path: "purchase_return_update",
      meta: { title: "编辑采购退货单", permission: "purchase_return_update" },
      component: () => import("@/views/purchasing/purchaseReturnUpdate/index"),
    },
    {
      path: "return_record",
      meta: { title: "采购退货", permission: "return_record" },
      component: () => import("@/views/purchasing/returnRecord/index"),
    },
    {
      path: "return_record_detail",
      meta: { title: "采购退货单详情", permission: "return_record_detail" },
      component: () => import("@/views/purchasing/returnRecordDetail/index"),
    },
  ],
};
