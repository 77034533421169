import VueRouter from "vue-router";
import user from "./user";
import account from "./account";
import manage from "./manage";
import system from "./system";
import report from "./report";
import basicData from "./basicData";
import goods from "./goods";
import purchasing from "./purchasing";
import sale from "./sale";
import warehouse from "./warehouse";
import finance from "./finance";
import production from "./production";
import kanban from "./kanban";
import approval from "./approval";
import inspection from "./inspection";
import equipment from "./equipment";

const index = {
  path: "/",
  meta: { title: "首页" },
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/home",
  children: [
    {
      path: "/home",
      name: "home",
      meta: { title: "首页" },
      component: () => import("@/views/home/Home"),
    },
  ],
};

const routes = [
  index,
  user,
  account,
  manage,
  system,
  report,
  basicData,
  goods,
  purchasing,
  sale,
  warehouse,
  finance,
  production,
  kanban,
  approval,
  inspection,
  equipment,
];

export default new VueRouter({ mode: "history", routes });
