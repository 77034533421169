export default {
  path: "/kanban",
  redirect: "/kanban/production",
  component: () => import("@/layouts/KanbanLayout"),
  children: [
    {
      path: "production",
      meta: { title: "生产看板", permission: "productionKanban" },
      component: () => import("@/views/kanban/productionKanban/index"),
    },
  ],
};
